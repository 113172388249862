<template>
  <div id="app">
    <img style="width: 100vw; display: block;" :src="data.url"/>
  </div>
</template>

<script>
import Vue from 'vue'
import {Lazyload} from 'vant';
Vue.use(Lazyload);

export default {
  name: "index_week_menus",
  data() {
    return {
      data: {
      }
    }
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data);
  }
}
</script>

<style scoped>

</style>